/* eslint-disable react/prop-types */
import * as React from 'react';
import styled from 'styled-components/macro';
import { TimelineBoxItem } from './components/TimelineBoxItem';
import { colorsV2 } from '../../style/colors-v2';
import { AboutUsSectionTemplate } from './components/AboutUsSectionTemplate';
import {
  fromScreen,
  useFromScreens
} from '../../utils/media-query/responsive.util';

const Container = styled.div`
  width: 100%;
  display: grid;
  row-gap: 32px;

  ${fromScreen(458)} {
    width: 425px;
  }

  ${fromScreen(776)} {
    width: 712px;
  }

  ${fromScreen(1144)} {
    width: 1080px;
  }
`;

const TopSection = styled.div`
  padding: 0 32px;
  display: grid;
  grid-auto-flow: column;
  column-gap: 40px;
  border-bottom: 1px solid ${colorsV2.gray40};
`;

const BottomSection = styled.div`
  padding: 0 132px;
  display: grid;
  grid-auto-flow: column;
  column-gap: 40px;
`;

const Section = styled.div`
  display: grid;
  column-gap: 40px;

  .timeline-box-1 {
    .line-top {
      background-color: transparent;
    }
  }
`;

export const DevelopmentHistory = () => {
  const [, from776] = useFromScreens([0, 776]);
  const renderBody = React.useMemo(() => {
    if (from776) {
      return (
        <div>
          <TopSection>
            <TimelineBoxItem
              bulletPosition="top"
              title="Năm 2016"
              description="DOL bắt đầu cung cấp các khóa học luyện thi IELTS, đánh dấu sự thành công ban đầu với sự hỗ trợ tận tâm và phương pháp học sâu, thông minh."
            />
            <TimelineBoxItem
              bulletPosition="top"
              title="Năm 2021"
              description="Xuất bản cuốn sách Thông não IELTS Reading cùng Linearthinking và ra mắt hệ thống LMS và nền tảng tự học tiếng Anh trực tuyến dolenglish.vn."
            />
            <TimelineBoxItem
              bulletPosition="top"
              title="Năm 2024"
              description="DOL English đã mở rộng mạng lưới với hơn 16 chi nhánh, phủ sóng từ thành phố Hồ Chí Minh đến thủ đô Hà Nội và thành phố Đà Nẵng."
            />
          </TopSection>
          <BottomSection>
            <TimelineBoxItem
              bulletPosition="bottom"
              title="Năm 2020"
              description="Phương pháp Linearthinking của DOL nhận được chứng nhận sở hữu trí tuệ và xuất hiện trên VTV7."
            />
            <TimelineBoxItem
              bulletPosition="bottom"
              title="Năm 2022"
              description="DOL được vinh danh trên các phương tiện truyền thông lớn và nhận giải thưởng vì dự án EDTECH. Ra mắt Từ Điển Việt Anh DOL Dictionary và tổ chức thành công 100+ Workshop."
            />
          </BottomSection>
        </div>
      );
    }
    return (
      <Section>
        <TimelineBoxItem
          className="timeline-box-1"
          bulletPosition="top"
          title="Năm 2016"
          description="DOL bắt đầu cung cấp các khóa học luyện thi IELTS, đánh dấu sự thành công ban đầu với sự hỗ trợ tận tâm và phương pháp học sâu, thông minh."
        />
        <TimelineBoxItem
          bulletPosition="top"
          title="Năm 2020"
          description="Phương pháp Linearthinking của DOL nhận được chứng nhận sở hữu trí tuệ và xuất hiện trên VTV7."
        />
        <TimelineBoxItem
          bulletPosition="top"
          title="Năm 2021"
          description="Xuất bản cuốn sách Thông não IELTS Reading cùng Linearthinking và ra mắt hệ thống LMS và nền tảng tự học tiếng Anh trực tuyến dolenglish.vn."
        />
        <TimelineBoxItem
          bulletPosition="top"
          title="Năm 2022"
          description="DOL được vinh danh trên các phương tiện truyền thông lớn và nhận giải thưởng vì dự án EDTECH. Ra mắt Từ Điển Việt Anh DOL Dictionary và tổ chức thành công 100+ Workshop."
        />
        <TimelineBoxItem
          bulletPosition="top"
          title="Năm 2024"
          description="DOL English đã mở rộng mạng lưới với hơn 16 chi nhánh, phủ sóng từ thành phố Hồ Chí Minh đến thủ đô Hà Nội và thành phố Đà Nẵng."
        />
      </Section>
    );
  }, [from776]);
  return (
    <Container>
      <AboutUsSectionTemplate title="Lịch sử phát triển" body={renderBody} />
    </Container>
  );
};
