/* eslint-disable react/prop-types */
import * as React from 'react';
import styled from 'styled-components/macro';
import { fromScreen } from 'utils/media-query/responsive.util';
import { CardEcosystem } from './components/CardEcosystem';
import { IconsColorIELTSOnlineTest } from '../../components/Icon/SVGIcons/IconsColorIELTSOnlineTest';
import { IconsColorStudentLearning } from '../../components/Icon/SVGIcons/IconsColorStudentLearning';
import { IconsColorDictionaryTranslate } from '../../components/Icon/SVGIcons/IconsColorDictionaryTranslate';
import { IconsColorGrammar } from '../../components/Icon/SVGIcons/IconsColorGrammar';
import { AboutUsSectionTemplate } from './components/AboutUsSectionTemplate';

const Container = styled.div`
  max-width: 100%;
  display: grid;
  row-gap: 32px;
`;

const Content = styled.div`
  display: grid;
  gap: 16px;

  ${fromScreen(458)} {
    gap: 20px;
  }

  ${fromScreen(776)} {
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }

  ${fromScreen(1144)} {
    gap: 24px;
  }
`;

export const DOLEcosystem = () => {
  return (
    <Container>
      <AboutUsSectionTemplate
        title="Hệ thống công nghệ DOL Ecosystem"
        description="DOL Ecosystem là hệ sinh thái công nghệ tích hợp tiên tiến giúp hỗ trợ quá trình học của học viên tại DOL English, từ việc tự học tại nhà đến việc luyện tập và cải thiện kỹ năng ngôn ngữ."
        body={
          <Content>
            <CardEcosystem
              icon={<IconsColorIELTSOnlineTest color="red100" />}
              title="DOL Tự Học"
              intro="DOL Tự Học cung cấp một loạt các tài nguyên và công cụ hỗ trợ cho việc tự học IELTS tại nhà, giúp học viên tiết kiệm chi phí và linh hoạt trong việc quản lý thời gian học."
              featureIntro="Các tính năng của DOL Tự Học bao gồm:"
              features={[
                'IELTS Online test (Hệ thống kiểm tra trực tuyến)',
                'IELTS Listening/ IELTS Reading Practice Tests',
                'Kho bài mẫu Writing/ Speaking',
                'Nghe chép chính tả',
                'Học Tiếng Anh qua tin tức',
                'Làm và nhận bài sửa assignment'
              ]}
              link="https://tuhocielts.dolenglish.vn/"
            />
            <CardEcosystem
              icon={<IconsColorStudentLearning color="red100" />}
              title="DOL SuperLMS"
              intro="DOL SuperLMS là hệ thống học tập dành riêng cho học viên của DOL, giáo viên sẽ theo dõi sát sao quá trình làm bài của học viên, giúp việc học tiếng Anh trở nên dễ dàng, và tiết kiệm thời gian hơn. "
              featureIntro="Các tính năng của DOL SuperLMS bao gồm:"
              features={[
                'Thống kê điểm danh và xem chương trình học từng buổi',
                'Đa dạng công cụ luyện tập, kho bài tập được biên soạn riêng',
                'Giáo viên xem và sửa bài chi tiết cho từng học viên',
                'Bài chấm Writing/Speaking kèm giọng nói',
                'AI Writing & Speaking mock test áp dụng trí tuệ nhân tạo',
                'Giao diện thi thật 4 kỹ năng'
              ]}
              link="https://superlms.dolenglish.vn/"
            />
            <CardEcosystem
              icon={<IconsColorDictionaryTranslate color="red100" />}
              title="DOL Dictionary"
              intro="DOL Dictionary cung cấp các công cụ tra cứu từ điển và dịch thuật tiếng Anh một cách nhanh chóng và tiện lợi. "
              featureIntro="Các tính năng của DOL Dictionary bao gồm:"
              features={[
                'Tra cứu từ điển Việt - Anh đầy đủ nghĩa nhất',
                'Dịch sát nghĩa tiếng Anh bằng tiếng Việt kèm ví dụ',
                'Ghi chú về cách sử dụng viết riêng cho từng từ'
              ]}
              link="https://tudien.dolenglish.vn/"
            />
            <CardEcosystem
              icon={<IconsColorGrammar color="red100" />}
              title="DOL Grammar"
              intro="DOL Grammar là công cụ học ngữ pháp tiếng Anh dành riêng cho người Việt, cung cấp lý thuyết ngữ pháp từ cơ bản đến nâng cao và các bài tập đặc biệt để học viên thực hành."
              featureIntro="Các tính năng của DOL Grammar bao gồm:"
              features={[
                'Lý thuyết ngữ pháp từ cơ bản đến nâng cao, nội dung ngắn gọn, dễ hiểu',
                'Bài tập độc quyền dành riêng cho từng chủ đề lý thuyết',
                'Theo dõi tiến độ học tập.'
              ]}
              link="https://grammar.dolenglish.vn/"
            />
          </Content>
        }
      />
    </Container>
  );
};
