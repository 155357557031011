/* eslint-disable react/prop-types */
import * as React from 'react';
import styled from 'styled-components/macro';
import { colorsV2 } from 'style/colors-v2';
import { fromScreen } from '../../../utils/media-query/responsive.util';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  &.bullet-top {
    .line-top {
      display: block;
      width: 1px;
      height: 6px;
      background-color: ${colorsV2.gray40};
    }

    ${fromScreen(776)} {
      margin-top: 6px;

      .line-top {
        display: none;
      }
    }
  }

  &.bullet-bottom {
    margin-bottom: 6px;
  }

  .line {
    width: 1px;
    height: 100%;
    background-color: ${colorsV2.gray40};
  }

  .bullet {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: ${colorsV2.black80};
  }
`;

export const BulletLine = ({ bulletPosition = 'bottom' }) => {
  if (bulletPosition === 'top') {
    return (
      <Container className="bullet-top">
        <div className="line-top" />
        <div className="bullet" />
        <div className="line" />
      </Container>
    );
  }
  return (
    <Container className="bullet-bottom">
      <div className="line" />
      <div className="bullet" />
    </Container>
  );
};
