import * as React from 'react';
import styled from 'styled-components';

import { fromScreen } from 'utils/media-query/responsive.util';
import MainLayout from 'components/Layout/MainLayout';
import HeaderSchedulePage from 'shared/Header/HeaderSchedulePage';
import MySEO from 'shared/MySEO';
import { aboutPage } from 'shared/MySEO/constant';
import PageBanner from 'shared/AboutUs/Header';
import { WhoWeAre } from 'shared/AboutUs/WhoWeAre';
import { withHomePageSection } from 'components/withHomePageSection';
import { FixedTopBarScholarshipScheduleNOffline } from 'shared/SchedulePage/FixedTopBarScholarship/FixedTopBarScholarshipScheduleNOffline';
import { DevelopmentHistory } from '../shared/AboutUs/DevelopmentHistory';
import { OurMission } from '../shared/AboutUs/OurMission';
import { DOLEcosystem } from '../shared/AboutUs/DOLEcosystem';
import { OurAchievement } from '../shared/AboutUs/OurAchievement';
import { WhoCanJoin } from '../shared/AboutUs/WhoCanJoin';
import { WhatYouCanFind } from '../shared/AboutUs/WhatYouCanFind';

const Space = styled.div`
  padding: 32px 0;
  display: grid;
  gap: 64px;

  ${fromScreen(776)} {
    padding: 100px 0;
    gap: 160px;
  }
`;

const SectionBasicContent = withHomePageSection(() => (
  <Space>
    <WhoWeAre />
    <DevelopmentHistory />
  </Space>
));

const SectionAchievementContent = withHomePageSection(() => (
  <Space>
    <DOLEcosystem />
    <OurAchievement />
  </Space>
));

const SectionWhatYouCanFind = withHomePageSection(() => (
  <Space>
    <WhatYouCanFind />
  </Space>
));
const AboutUsPage = ({ location }) => {
  return (
    <MainLayout
      noHeader
      hideSideCTA
      renderCustomHeader={() => <HeaderSchedulePage />}
      showFooterRegister
    >
      <MySEO
        slug={location.pathname}
        title="Giới thiệu về DOL ENGLISH - Trung Tâm Anh ngữ hàng đầu HCM"
        h1="Giới thiệu DOL English"
        hidePostfix
        description="DOL English - trung tâm anh ngữ hàng đầu tại Tp HCM với phương pháp giảng dạy tiếng Anh độc quyền, hiệu quả vượt trội được công nhận bởi hàng ngàn học viên"
      >
        <script type="application/ld+json">{JSON.stringify(aboutPage)}</script>
      </MySEO>
      <FixedTopBarScholarshipScheduleNOffline />
      <PageBanner
        title={`<span class="highlight">Giới thiệu</span> DOL ENGLISH`}
        description="DOL English (IELTS Đình Lực) là học viện Tiếng Anh Tư Duy hàng đầu tại Việt Nam, với phương pháp học Linearthinking độc quyền và hệ thống công nghệ DOL Ecosystem tự phát triển. Đội ngũ giáo viên chuyên nghiệp, cùng tỷ lệ học viên hài lòng lên đến 98%, đã giúp 30000+ học viên cải thiện điểm số Anh ngữ của mình. Với cơ sở vật chất hiện đại và chương trình học tập cá nhân hóa, DOL English cam kết mang lại trải nghiệm học tập hiệu quả, thú vị và đầy cảm hứng."
      />
      <SectionBasicContent />
      <OurMission />
      <SectionAchievementContent />
      <WhoCanJoin />
      <SectionWhatYouCanFind />
    </MainLayout>
  );
};

export default React.memo(AboutUsPage);
