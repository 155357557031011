/* eslint-disable react/prop-types */

import * as React from 'react';
import styled from 'styled-components/macro';
import { AboutUsSectionTemplate } from './components/AboutUsSectionTemplate';
import { CardAchievement } from './components/CardAchievement';
import { fromScreen } from '../../utils/media-query/responsive.util';

const List = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 28px;

  ${fromScreen(425)} {
    width: 425px;
  }
  ${fromScreen(776)} {
    width: 712px;
    column-gap: 20px;
    row-gap: 32px;
  }

  ${fromScreen(1144)} {
    width: 1080px;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    column-gap: 24px;
    row-gap: 48px;
  }
`;
export const OurAchievement = () => {
  return (
    <AboutUsSectionTemplate
      title="Thành tựu đạt được"
      description="DOL English đã từng bước khẳng định vị thế của mình trong lĩnh vực giáo dục tiếng Anh. Với sứ mệnh tạo ra một cộng đồng học tiếng Anh thông minh, hiệu quả, DOL English cam kết mang lại những sản phẩm giáo dục tiếng Anh đột phá."
      body={
        <List>
          <CardAchievement value="10K" label="Học viên đạt 7.0+" />
          <CardAchievement value="30K+" label="Học viên đã học" />
          <CardAchievement value="28K+" label="Review 5 sao từ học viên+" />
          <CardAchievement value="100+" label="Giáo viên trình độ IELTS 8.0" />
          <CardAchievement value="16+" label="Chi nhánh trên cả nước" />
          <CardAchievement value="500+" label="Workshop đã thực hiện" />
        </List>
      }
    />
  );
};
