/* eslint-disable react/prop-types */

import * as React from 'react';
import styled from 'styled-components/macro';
import { Typography } from 'components/DesignSystemV2/Typography';
import { colorsV2 } from 'style/colors-v2';
import { LazyImage } from 'components/LazyImage';
import { fromScreen } from 'utils/media-query/responsive.util';

const HeaderWrapper = styled.div`
  .title {
    margin-bottom: 12px;
  }

  ${fromScreen(1144)} {
    .title {
      margin-bottom: 20px;
    }
  }
`;

export const Container = styled.div`
  .top-section {
    display: grid;
    width: 100%;
    gap: 24px;

    .description {
      &:not(:last-child) {
        margin-bottom: 8px;
      }
    }
  }

  img {
    border-radius: 8px;
  }

  .bottom-section,
  .my-class-image {
    display: none;
  }

  .teacher-group-image-mobile {
    display: block;
    width: 100%;
  }

  ${fromScreen(458)} {
    width: 425px;

    .my-class-image {
      display: block;
      float: right;
      width: 150px;
      margin: 0 0 20px 20px;

      img {
        width: 100%;
      }
    }

    .teacher-group-image-mobile {
      display: none;
    }

    .header-wrapper {
      display: inline;
    }

    .top-section {
      display: block;
    }

    .bottom-section {
      margin-top: 20px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 20px;
    }
  }

  ${fromScreen(776)} {
    width: 712px;

    .my-class-image {
      width: 100%;

      img {
        width: 100%;
      }
    }

    .header-wrapper {
      display: grid;
      order: -1;
      align-content: baseline;
    }

    .top-section {
      display: grid;
      grid-template-columns: 1fr minmax(0, 1fr);
      column-gap: 48px;
    }

    .bottom-section {
      column-gap: 48px;
      margin-top: 48px;

      .student-correct-image {
        margin-top: 96px;
      }
    }
  }

  ${fromScreen(1144)} {
    width: 1080px;

    .top-section {
      grid-template-columns: 660px minmax(0, 1fr);
      column-gap: 96px;
    }
  }
`;

export const WhoWeAre = () => {
  return (
    <Container>
      <div className="top-section">
        <LazyImage
          src="https://gqefcpylonobj.vcdn.cloud/directus-upload/4d37a1b1-47d0-4f5d-8d08-33367a98afa8.png"
          alt="Lớp học của DOL"
          className="my-class-image"
        />
        <LazyImage
          src="https://gqefcpylonobj.vcdn.cloud/directus-upload/f8804389-2853-4228-a2f7-bed7ee4491a8.png"
          alt="Đội ngũ giáo viên"
          className="teacher-group-image-mobile"
        />
        <HeaderWrapper className="header-wrapper">
          <Typography
            variant="bold/24-32"
            tabletVariant="bold/32-40"
            desktopVariant="bold/40-48"
            v3
            as="h2"
            className="title"
          >
            Chúng tôi là ai?
          </Typography>
          <div className="description">
            <Typography
              variant="regular/14-20"
              v3
              color={colorsV2.black80}
              className="description"
            >
              {' DOL English, Học viện Tiếng Anh tư duy đầu tiên tại Việt Nam, với\n' +
                '            Linearthinking độc quyền đã được công nhận sự hiệu quả bởi 1000+ học\n' +
                '            viên.'}
            </Typography>
            <Typography
              variant="regular/14-20"
              v3
              color={colorsV2.black80}
              className="description"
            >
              Ấp ủ dự định từ năm 2016 với một lớp học nhỏ tại chung cư Đào Duy
              Từ, DOL English chính thức được thành lập bởi anh Lê Đình Lực vào
              năm 2018. Hiện nay, DOL English đã phát triển thành một trung tâm
              uy tín với trụ sở chính đặt tại: Hẻm 458/14, 3 Tháng 2, P12, Q.
              10, TP.HCM. DOL English còn được biết đến với các tên gọi khác như
              DOL, DOL English, IELTS Đình Lực, và Anh ngữ Đình Lực.
            </Typography>
            <Typography
              variant="regular/14-20"
              v3
              color={colorsV2.black80}
              className="description"
            >
              {'DOL là viết tắt của từ “Dolphin”, nghĩa là “cá heo”. Cá heo có đặc\n' +
                '            tính thông minh, luôn giúp đỡ đồng loại và con người. Việc chọn tên\n' +
                '            "Dolphin" làm biểu tượng, thể hiện mong muốn vươn tầm như cá heo\n' +
                '            thông minh.'}
            </Typography>
            <Typography
              variant="regular/14-20"
              v3
              color={colorsV2.black80}
              className="description"
            >
              {'"Chúng tôi muốn xây dựng một start-up là tổng hòa giữa sự thông minh\n' +
                '            và tận dụng các công cụ công nghệ vào việc truyền tải kiến thức theo\n' +
                '            cách có tâm nhất", đó là khát vọng của DOL English.'}
            </Typography>
          </div>
        </HeaderWrapper>
      </div>
      <div className="bottom-section">
        <LazyImage
          src="https://gqefcpylonobj.vcdn.cloud/directus-upload/f8804389-2853-4228-a2f7-bed7ee4491a8.png"
          alt="Đội ngũ giáo viên"
          className="teacher-group-image"
        />
        <LazyImage
          src="https://gqefcpylonobj.vcdn.cloud/directus-upload/b11ac2bb-a1d1-4727-8a3e-557df088d96e.png"
          alt="Sửa lỗi sai cho học viên"
          className="student-correct-image"
        />
      </div>
    </Container>
  );
};
