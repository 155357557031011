/* eslint-disable react/prop-types */
import * as React from 'react';
import styled from 'styled-components/macro';
import { colorsV2 } from 'style/colors-v2';
import { Typography } from '../../../components/DesignSystemV2/Typography';
import { fromScreen } from '../../../utils/media-query/responsive.util';

const Container = styled.div`
  display: grid;
  row-gap: 24px;

  .title {
    display: grid;
    row-gap: 12px;
  }

  ${fromScreen(776)} {
    .title {
      max-width: 580px;
    }
  }

  ${fromScreen(1144)} {
    row-gap: 32px;

    .title {
      display: grid;
      row-gap: 20px;
      max-width: 720px;
    }
  }
`;
export const AboutUsSectionTemplate = ({ title, description, body }) => {
  return (
    <Container className="about-us-section-template">
      <div className="title">
        <Typography
          variant="bold/24-32"
          tabletVariant="bold/32-40"
          desktopVariant="bold/40-48"
          v3
          as="h2"
        >
          {title}
        </Typography>
        {description && (
          <Typography variant="regular/14-20" v3 color={colorsV2.black80}>
            {description}
          </Typography>
        )}
      </div>
      {body}
    </Container>
  );
};
