/* eslint-disable react/prop-types */
import * as React from 'react';
import styled from 'styled-components/macro';
import { Typography } from 'components/DesignSystemV2/Typography';
import { colorsV2 } from '../../style/colors-v2';
import { LazyImage } from '../../components/LazyImage';
import { fromScreen } from '../../utils/media-query/responsive.util';

const ShadowLayer = styled.div`
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.8);

  .backdrop {
    width: 100%;
    position: absolute;
    z-index: -1;
    height: 100%;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }

    ${fromScreen(776)} {
      img {
        object-position: top -100px right 10px;
      }
    }

    ${fromScreen(1144)} {
      img {
        object-position: top -200px right 10px;
      }
    }
  }
`;

const Container = styled.div`
  width: 100vw;
  position: relative;
  height: 424px;
  overflow: hidden;

  ${fromScreen(458)} {
    height: 404px;
  }

  ${fromScreen(776)} {
    height: 476px;
  }
`;

const BodyWrapper = styled.div`
  margin: 0 auto;
  padding: 32px 16px;
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  display: grid;
  row-gap: 12px;

  .title {
    text-align: center;
  }

  .description {
    display: grid;
    row-gap: 8px;
  }

  ${fromScreen(458)} {
    padding: 48px 16px;
    width: 425px;
  }

  ${fromScreen(776)} {
    padding: 100px 32px;
    width: 712px;
    row-gap: 32px;
  }

  ${fromScreen(1144)} {
    width: 1080px;
  }
`;

export const OurMission = () => {
  return (
    <Container>
      <ShadowLayer>
        <LazyImage
          className="backdrop"
          handle="DXtBlOvUSyqree5dsRZ9"
          alt="dolenglish-mission"
        />
      </ShadowLayer>
      <BodyWrapper>
        <Typography
          variant="bold/24-32"
          tabletVariant="bold/32-40"
          desktopVariant="bold/40-48"
          className="title"
          color={colorsV2.white100}
          v3
          as="h2"
        >
          Sứ mệnh của DOL English
        </Typography>
        <div className="description">
          <Typography variant="regular/14-20" v3 color={colorsV2.white100}>
            {'Sứ mệnh của DOL là tạo ra phương pháp học tiếng Anh bản chất, hiệu quả\n' +
              '        và tiết kiệm thời gian, từ đó nâng cao tư duy logic, tư duy phản biện và\n' +
              '        kỹ năng giải quyết vấn đề cho học viên. Chúng tôi không chỉ hướng tới\n' +
              '        việc giúp học viên thành thạo tiếng Anh, mà còn giỏi việc học các ngôn\n' +
              '        ngữ khác, cùng khả năng xử lý các vấn đề trong cuộc sống.'}
          </Typography>
          <Typography variant="regular/14-20" v3 color={colorsV2.white100}>
            {'DOL truyền cảm hứng về "tinh thần tạo giá trị", khích lệ sự đổi mới,\n' +
              '        sáng tạo, vượt qua khó khăn và kiên trì. DOL English khát vọng đưa\n' +
              '        phương pháp học tiếng Anh và công nghệ của người Việt ra thế giới, đem\n' +
              '        lại giá trị và ảnh hưởng toàn cầu.'}
          </Typography>
          <Typography variant="regular/14-20" v3 color={colorsV2.white100}>
            {' Trong vòng 10 năm tới, chúng tôi sẽ định hình tiêu chuẩn cho thị trường\n' +
              '        giáo dục tiếng Anh ở mọi cấp độ và độ tuổi.'}
          </Typography>
        </div>
      </BodyWrapper>
    </Container>
  );
};
