/* eslint-disable react/prop-types */

import * as React from 'react';
import styled from 'styled-components/macro';
import { AboutUsSectionTemplate } from './components/AboutUsSectionTemplate';
import { CardAboutUs } from './components/CardAboutUs';
import { fromScreen } from '../../utils/media-query/responsive.util';
import { IconsFeatureBulb } from '../../components/Icon/SVGIcons/IconsFeatureBulb';
import { IconsLearningCertificate } from '../../components/Icon/SVGIcons/IconsLearningCertificate';
import { IconsCommunicationHeartMessage } from '../../components/Icon/SVGIcons/IconsCommunicationHeartMessage';
import { IconsLearningMonitorUser } from '../../components/Icon/SVGIcons/IconsLearningMonitorUser';
import { IconsLearningModernBuilding } from '../../components/Icon/SVGIcons/IconsLearningModernBuilding';
import { IconsLearningReading } from '../../components/Icon/SVGIcons/IconsLearningReading';

const List = styled.div`
  display: grid;
  gap: 24px;

  ${fromScreen(458)} {
    margin: 0 auto;
    width: 425px;
    gap: 20px;
  }

  ${fromScreen(776)} {
    width: 712px;
    gap: 32px;
  }

  ${fromScreen(1144)} {
    grid-template-columns: 1fr 1fr;
    width: 1080px;
  }
`;
export const WhatYouCanFind = () => {
  return (
    <AboutUsSectionTemplate
      title="Bạn tìm được gì ở DOL English?"
      body={
        <List>
          <CardAboutUs
            icon={<IconsFeatureBulb />}
            title="Hệ tư duy Linearthinking"
            descriptions={[
              'Linearthinking là Hệ phương pháp học Tiếng Anh Tư duy độc quyền dành cho người Việt do anh Lê Đình Lực sáng tạo ra. Mô hình học sử dụng tư duy toán Linearthinking và trí tuệ nhân tạo (AI) để cá nhân hóa quá trình học và rút ngắn thời gian học tiếng Anh một cách thông minh cho từng học viên. Linearthinking được chứng nhận bởi Cục Sở hữu Trí tuệ và xuất bản thành sách để chia sẻ với học sinh, sinh viên tại nhiều trường trung học và đại học, cũng như xuất hiện trên nhiều báo đài uy tín.'
            ]}
            link="https://www.dolenglish.vn/gioi-thieu-linearthinking"
          />
          <CardAboutUs
            icon={<IconsLearningCertificate />}
            title="Chương trình học tiếng Anh"
            descriptions={[
              'Chương trình học tiếng Anh tại DOL English bao gồm 2 nhóm chính là các Chương trình luyện thi chứng chỉ Tiếng Anh như IELTS, TOEIC, SAT... và Chương trình Tiếng Anh tổng quát bao gồm Tiếng Anh giao tiếp, Tiếng Anh theo nhu cầu. ',
              'Bạn có thể tìm hiểu chi tiết chương trình học, giáo viên đứng lớp, yêu cầu đầu vào, đầu ra đảm bảo cũng như tất cả các thông tin khác cần thiết để bạn lựa chọn và đăng ký khóa học. Lịch khai giảng tất cả các lớp cũng như các chính sách ưu đãi cũng được DOL English cập nhật liên tục hàng tuần.'
            ]}
            link="https://www.dolenglish.vn/khoa-hoc-ielts"
          />
          <CardAboutUs
            icon={<IconsCommunicationHeartMessage />}
            title="Đội ngũ giáo viên giỏi chuyên môn, nhiệt huyết và tận tâm"
            descriptions={[
              'Đội ngũ giáo viên của DOL được tuyển chọn từ những chuyên gia giỏi, có kinh nghiệm dày dặn trong việc giảng dạy các kỳ thi quốc tế như IELTS, SAT, TOEIC. Họ không chỉ là những Thạc sĩ, Thủ khoa, Á khoa, mà còn là giảng viên của các trường Đại học danh tiếng. Đồng thời, họ cũng được chứng nhận giảng dạy quốc tế và được đào tạo bài bản.',
              'Đội ngũ giáo viên của DOL  luôn đặt học viên của mình lên hàng đầu, không chỉ giảng dạy và truyền đạt kiến thức tại lớp, mà còn sẵn sàng hỗ trợ học viên ngoài giờ học.'
            ]}
            link="https://www.dolenglish.vn/linearteachers"
          />
          <CardAboutUs
            icon={<IconsLearningMonitorUser />}
            title="Nền tảng siêu công nghệ hỗ trợ việc dạy và học"
            descriptions={[
              'Hệ thống công nghệ siêu thông minh do chính đội ngũ của DOL phát triển với mục đích hỗ trợ tối đa cho quá trình dạy và học. Học viên được cung cấp lộ trình học tập cụ thể từng tuần cùng với một kho bài tập đa dạng và dễ thao tác. Đồng thời, các bài tập Writing và Speaking của học viên được chấm chữa chi tiết và kịp thời ngay trên hệ thống.',
              'Hệ thống công nghệ còn tích hợp trí tuệ nhân tạo để đánh giá bài viết và bài nói của học viên theo chuẩn IELTS, giúp họ có nhiều cơ hội để luyện tập dễ dàng và tiện lợi. Ngoài ra, học viên có thể làm quen sớm với cấu trúc bài thi thông qua tính năng thi thử IELTS Online để tự tin hơn khi bước vào kỳ thi thật.',
              'Bằng cách này, giáo viên và học viên có thể theo dõi và điều chỉnh quá trình học tập một cách tối ưu, giúp học viên đạt được mục tiêu IELTS của mình nhanh chóng, hiệu quả và thông minh nhất.'
            ]}
            link="https://www.dolenglish.vn/nen-tang-cong-nghe"
          />
          <CardAboutUs
            icon={<IconsLearningModernBuilding />}
            title="Cơ sở vật chất hiện đại"
            descriptions={[
              'DOL đầu tư vào cơ sở vật chất “xịn xò", hiện đại nhất (phòng học thông thoáng và sạch sẽ, phòng tự học yên tĩnh, sảnh chờ,…) tạo môi trường học thoải mái và tiện nghi nhất cho học viên.'
            ]}
            link="https://www.dolenglish.vn/he-thong-co-so"
          />
          <CardAboutUs
            icon={<IconsLearningReading />}
            title="Kho bài viết chia sẻ kiến thức học Tiếng Anh tư duy"
            descriptions={[
              'Các bài viết sẽ cung cấp cho bạn những kiến thức chính xác nhất cũng như phân tích những lỗi mà chính các bạn đang mắc phải, đồng thời có giải pháp để các bạn tiến bộ nhanh hơn. Bao gồm bài viết tổng quan và chi tiết về các kỹ năng, ngữ pháp, từ vựng và tài liệu liên quan, cùng với kho bài mẫu Writing và Speaking.'
            ]}
            link="https://www.dolenglish.vn/category/ielts-knowledge"
          />
        </List>
      }
    />
  );
};
