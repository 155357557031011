/* eslint-disable react/prop-types */
import * as React from 'react';
import styled from 'styled-components/macro';
import { Typography } from 'components/DesignSystemV2/Typography';
import { fromScreen } from 'utils/media-query/responsive.util';
import { colorsV2 } from '../../../style/colors-v2';

const Container = styled.div`
  display: grid;
  gap: 8px;
  border-radius: 20px;
  border: 1px solid ${colorsV2.yellow120};
  padding: 16px;

  .icon-wrapper {
    svg {
      color: ${colorsV2.yellow120};
      width: 24px;
      height: 24px;
    }
  }

  ${fromScreen(776)} {
    padding: 24px;

    .icon-wrapper {
      svg {
        width: 32px;
        height: 32px;
      }
    }
  }
`;

export const CardUser = props => {
  const { icon, title, description } = props;
  return (
    <Container>
      <div className="icon-wrapper">{icon}</div>
      <Typography
        variant="semi-bold/16-28"
        tabletVariant="semi-bold/20-28"
        v3
        as="h3"
      >
        {title}
      </Typography>
      <Typography variant="regular/14-20" v3 color={colorsV2.black80}>
        {description}
      </Typography>
    </Container>
  );
};
