/* eslint-disable react/prop-types */
import * as React from 'react';
import styled from 'styled-components/macro';
import { Typography } from 'components/DesignSystemV2/Typography';
import { fromScreen } from 'utils/media-query/responsive.util';
import ButtonText from 'components/ButtonText';
import { colorsV2 } from '../../../style/colors-v2';

const Container = styled.div`
  display: grid;
  gap: 8px;
  border-radius: 20px;
  border: 1px solid ${colorsV2.gray20};
  padding: 16px;

  .description-paragraph {
    display: grid;
    row-gap: 4px;
  }

  .icon-wrapper {
    svg {
      color: ${colorsV2.neutralPrimary};
      width: 28px;
      height: 28px;
    }
  }

  ${fromScreen(776)} {
    padding: 24px;

    .description-paragraph {
      display: grid;
      row-gap: 8px;
    }

    .icon-wrapper {
      svg {
        width: 40px;
        height: 40px;
      }
    }
  }
`;

const DescriptionList = styled.ul`
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: ${colorsV2.black80};
  padding-inline-start: 16px;
  margin-block-start: 0;
  margin-block-end: 0;
`;

export const CardEcosystem = props => {
  const { icon, title, link, intro, featureIntro, features } = props;
  return (
    <Container>
      <div className="icon-wrapper">{icon}</div>
      <Typography
        variant="semi-bold/16-28"
        tabletVariant="semi-bold/20-28"
        v3
        as="h3"
      >
        {title}
      </Typography>
      <div className="description-paragraph">
        <Typography variant="regular/14-20" v3 color={colorsV2.black80}>
          {intro}
        </Typography>
        <Typography variant="regular/14-20" v3 color={colorsV2.black80}>
          {featureIntro}
        </Typography>
        <DescriptionList>
          {features?.map((eachOption, idx) => (
            <li key={idx}>{eachOption}</li>
          ))}
        </DescriptionList>
      </div>
      <a href={link} target="_blank" rel="noreferrer">
        <ButtonText content="Trải nghiệm ngay" type="primary" />
      </a>
    </Container>
  );
};
