/* eslint-disable react/prop-types */
import * as React from 'react';
import styled from 'styled-components/macro';
import { Typography } from 'components/DesignSystemV2/Typography';
import { colorsV2 } from '../../style/colors-v2';
import { CardUser } from './components/CardUser';
import { IconsTeacherOverviewDiploma } from '../../components/Icon/SVGIcons/IconsTeacherOverviewDiploma';
import { fromScreen } from '../../utils/media-query/responsive.util';
import { IconsLearningTravel } from '../../components/Icon/SVGIcons/IconsLearningTravel';
import { IconsLearningBooksApple } from '../../components/Icon/SVGIcons/IconsLearningBooksApple';
import { IconsTeacherOverviewGraduateHat } from '../../components/Icon/SVGIcons/IconsTeacherOverviewGraduateHat';
import { IconsFeatureSuitcase } from '../../components/Icon/SVGIcons/IconsFeatureSuitcase';

const Container = styled.div`
  width: 100%;
  background-color: ${colorsV2.paper};
  padding: 32px 16px;

  ${fromScreen(776)} {
    padding: 100px 32px;
  }
`;

const Content = styled.div`
  order: -1;
  margin: 0 auto;
  width: 100%;
  display: grid;
  row-gap: 20px;

  .section {
    display: grid;
    row-gap: 16px;
  }

  ${fromScreen(458)} {
    width: 425px;
    row-gap: 24px;

    .section {
      row-gap: 20px;
    }
  }

  ${fromScreen(776)} {
    order: 0;
    width: 712px;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    column-gap: 32px;

    .section {
      display: grid;
      row-gap: 32px;
    }
  }

  ${fromScreen(1144)} {
    width: 1080px;
    column-gap: 48px;

    .section {
      display: grid;
      row-gap: 48px;
    }
  }
`;

const TitleWrapper = styled.div`
  max-width: 420px;
  display: grid;
  row-gap: 12px;

  ${fromScreen(1144)} {
    row-gap: 20px;
  }
`;

export const WhoCanJoin = () => {
  return (
    <Container>
      <Content>
        <div className="section">
          <TitleWrapper>
            <Typography
              variant="bold/24-32"
              tabletVariant="bold/32-40"
              desktopVariant="bold/40-48"
              className="title"
              color={colorsV2.black100}
              v3
              as="h2"
            >
              DOL English phù hợp với đối tượng nào?
            </Typography>
            <Typography variant="regular/14-20" v3 color={colorsV2.black80}>
              DOL English cung cấp các khóa học tiếng Anh linh hoạt và đa dạng
              để đáp ứng nhu cầu của mọi đối tượng học viên.
            </Typography>
          </TitleWrapper>
          <CardUser
            icon={<IconsTeacherOverviewDiploma />}
            title="Người muốn nâng cao trình độ tiếng Anh"
            description="Có thể có những người đã có trình độ tiếng Anh khá tốt nhưng muốn nâng cao trình độ của mình hoặc chuẩn bị cho các kỳ thi quốc tế như IELTS, TOEFL, hoặc TOEIC."
          />
          <CardUser
            icon={<IconsLearningTravel />}
            title="Người muốn học tiếng Anh vì mục đích cá nhân"
            description="DOL English cung cấp các khóa học tiếng Anh linh hoạt và thú vị cho những người muốn học tiếng Anh để du lịch, giao tiếp với người nước ngoài, hoặc đơn giản là vì sở thích cá nhân."
          />
        </div>
        <div className="section">
          <CardUser
            icon={<IconsLearningBooksApple />}
            title="Học sinh cấp 1, cấp 2, và cấp 3"
            description="DOL English cung cấp các khóa học tiếng Anh được thiết kế đặc biệt để phù hợp với nhu cầu học tập của học sinh ở mọi trình độ rèn luyện kỹ năng ngôn ngữ một cách hiệu quả và phát triển khả năng giao tiếp tiếng Anh một cách tự tin."
          />
          <CardUser
            icon={<IconsTeacherOverviewGraduateHat />}
            title="Sinh viên"
            description="Sinh viên có thể tìm kiếm các khóa học tiếng Anh tại DOL English để cải thiện kỹ năng ngôn ngữ của mình, chuẩn bị cho các kỳ thi quốc tế như IELTS, TOEFL, hoặc TOEIC,... và nâng cao trình độ tiếng Anh để sẵn sàng cho sự nghiệp trong tương lai."
          />
          <CardUser
            icon={<IconsFeatureSuitcase />}
            title="Người đi làm"
            description="DOL English cung cấp các khóa học tiếng Anh chuyên biệt để phát triển kỹ năng giao tiếp, viết & nghe hiệu quả trong môi trường làm việc."
          />
        </div>
      </Content>
    </Container>
  );
};
