/* eslint-disable react/prop-types */
import * as React from 'react';
import styled from 'styled-components/macro';
import { Typography } from 'components/DesignSystemV2/Typography';
import { withHomePageSection } from 'components/withHomePageSection';
import { colorsV2 } from 'style/colors-v2';
import { LazyImage } from 'components/LazyImage';
import { fromScreen } from 'utils/media-query/responsive.util';

export const Container = styled.div`
  .title {
    margin-bottom: 20px;
    .highlight {
      position: relative;
      z-index: 1;
      &::after {
        content: '';
        position: absolute;
        background: ${colorsV2.yellow100};
        bottom: 3px;
        left: 0;
        height: 10px;
        width: 100%;
        opacity: 0.3;
        z-index: -1;
        bottom: 3px;
      }
    }
  }
  background-color: ${colorsV2.paper};
  margin: 0;
  padding: 32px 0px;
  text-align: left;
  position: relative;
  display: grid;
  grid-auto-flow: row;
  .course-icon {
    display: none;
  }
  .banner-text {
    .desc {
      min-height: 110px;
    }
  }

  ${fromScreen(776)} {
    padding: 100px 0;
    text-align: center;
    .banner-text {
      width: 712px;
      margin: 0 auto;
      br {
        display: none;
      }
    }
    .course-icon {
      display: block;
      position: absolute;
      &.top {
        top: 70px;
        right: 100px;
      }
    }
  }
  ${fromScreen(1144)} {
    .title {
      margin-bottom: 32px;
    }
    .banner-text {
      width: 712px;
    }
    .course-icon {
      &.top {
        right: 250px;
      }
    }
  }
`;

export const Title = ({ children }) => (
  <Typography
    variant="bold/32-40"
    tabletVariant="bold/40-48"
    desktopVariant="bold/48-60"
    as="h1"
    className="title"
    v3
  >
    {children}
  </Typography>
);

export const NormalText = ({ children }) => (
  <Typography
    variant="regular/14-20"
    tabletVariant="regular/16-24"
    color={colorsV2.black80}
    v3
    className="desc"
  >
    {children}
  </Typography>
);

const Header = ({ title, description }) => {
  return (
    <Container>
      <div className="banner-text">
        <Typography
          variant="bold/32-40"
          tabletVariant="bold/40-48"
          desktopVariant="bold/48-60"
          as="h1"
          className="title"
          v3
          dangerouslySetInnerHTML={{ __html: title }}
        />

        <NormalText>{description}</NormalText>
      </div>
      <LazyImage
        handle="RY1uavGWQqW2aZ0GGJDo"
        className="course-icon top"
        alt="icon-star"
      />
    </Container>
  );
};

export default withHomePageSection(Header, {
  style: {
    backgroundColor: colorsV2.paper
  }
});
