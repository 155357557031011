/* eslint-disable react/prop-types */
import * as React from 'react';
import styled from 'styled-components/macro';
import { Typography } from 'components/DesignSystemV2/Typography';
import { colorsV2 } from 'style/colors-v2';
import { BulletLine } from './BulletLine';

const Container = styled.div`
  display: grid;
  grid-auto-flow: column;
  column-gap: 16px;

  .main-content {
    display: grid;
    row-gap: 4px;
    align-content: baseline;
    padding-bottom: ${props => props.bulletPosition === 'top' && '24px'};
    padding-top: ${props => props.bulletPosition === 'bottom' && '24px'};
  }
`;

export const TimelineBoxItem = props => {
  const { title, description, bulletPosition, className } = props;
  return (
    <Container className={className} bulletPosition={bulletPosition}>
      <BulletLine bulletPosition={bulletPosition} />
      <div className="main-content">
        <Typography variant="semi-bold/16-20" v3 color={colorsV2.black100}>
          {title}
        </Typography>
        <Typography variant="regular/14-20" v3 color={colorsV2.black80}>
          {description}
        </Typography>
      </div>
    </Container>
  );
};
